<template>
  <div class="dictionariesBox">
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" type="index" label="序号"></el-table-column>
        <el-table-column align="center" prop="dictName" label="名称" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="dictType" label="类型"></el-table-column>
        <el-table-column align="center" prop="" label="操作" width="100">
          <template slot-scope="scope">
            <div>
              <p class="seeBox" @click="seeFun(scope.row)">查看</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="dialogVisible" top="80px" width="600px" :show-close="false"
    :close-on-click-modal="false":close-on-press-escape="false">
      <div class="dictionariesDia">
        <div class="tableBox">
          <el-table :data="diaData" style="width: 100%" max-height="600">
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column align="center" prop="dictValue" label="字典值名称"></el-table-column>
            <el-table-column align="center" prop="dictDesc" label="编码" width="100"></el-table-column>
          </el-table>
        </div>


        <div class="closeBtn">
          <el-button @click="handleClose">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {listBaseDictPage} from '@/api/index'
import {getDictValueListByDictType} from '@/api/index'
export default {
  data() {
    return {
      tableData:[],
      dialogVisible:false,
      diaData:[],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      listBaseDictPage({page:1,limit:20}).then(res=>{
        this.tableData = res.data.rows;
      })
    },
    seeFun(row){
      getDictValueListByDictType({dictType:row.dictType}).then(res=>{
        this.diaData = res.data;
      })
      this.dialogVisible = true
    },
    handleClose(){
      this.dialogVisible = false
    }
  },
}
</script>
<style lang="scss" scoped>
.dictionariesBox{
  .seeBox{
    cursor: pointer;
    color: #11a6ea;
  }

  .dictionariesDia{
    .closeBtn{
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
}
</style>